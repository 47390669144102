import axios from "axios";
import PortalCooperadoUtils from '../utilities/PortalCooperadoUtils';

const ESTATUTO_API_BASE_URL = PortalCooperadoUtils.apiCooperado;
const RESOURCE_V1 = `/v1/estatuto-social`;

class EstatutoSocialService {

    getEstatutoSocial() {
        return axios.get(ESTATUTO_API_BASE_URL + RESOURCE_V1);
    }
}

export default new EstatutoSocialService();