<template>
    <div class="col-12 lg:col-4">
        <div class="card height-100 flex flex-column">
            <div class="flex justify-content-center p-3 rounded-normal content-alt-bgcolor">
                <img src="layout/images/widgets/estatuto-social.png" alt="feature-security">
            </div>
            <div class="flex flex-column align-items-center mt-3">
                <h5>Estatuto Social</h5>
            </div>
            <div class="flex flex-column align-items-center mt-3">
                <Button icon="pi pi-download" @click="getPDF()" type="button" class="p-button-success mr-2" label="Download Estatuto Social"/>
            </div>
        </div>
    </div>
</template>
    
<script lang="js">   
    import EstatutoSocial from "../../service/EstatutoSocial";

    export default {
        name: 'EstatutoSocial', 
        data() {
            return {
                database: [],               
            }
        },

        created() { 
            this.getEstatuto();
        },

        methods: {
            getEstatuto(){     
                
                EstatutoSocial.getEstatutoSocial().then((response) => {
                    response.data;  
                                    
                });                               
            },
            
            getPDF(){
                EstatutoSocial.getEstatutoSocial().then((response) =>{
                
                let name = "Estatuto Social Integrada.pdf";
                const datasource = response.data.url;
                const link = document.createElement("a");
                link.target   = '_blank';
                link.href = datasource;
                link.setAttribute('download', name);
                link.click();
                })
            }
        } 
    }
    
</script>