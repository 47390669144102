<template>
    <EstatutoSocial :matricula="currentUser?.matricula"/>
</template>

<script lang="js">
    
    import EstatutoSocial from '../../components/download/EstatutoSocial.vue'

    export  default {
        computed: {
			currentUser() {

                return this.$store.state.auth.user;
			
            }
		},

        components: { EstatutoSocial }
    }

</script>